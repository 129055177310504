<template>
  <v-dialog v-model="dialog" persistent max-width="60%">
    <v-card>
      <v-card-title>
        <span class="md-text">Edit product</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
            <loadingProgress :loading="loading" text="Loading webistes..." />
          </v-col>
          <v-col cols="12" sm="12" md="12" class="ma-0" v-if="!loading" v-show="!confirmation">
            <div class="d-flex align-center justify-center" v-if="default_product.product">
              <div class="mr-4">
                <img class="product-image-edit" :src="default_product.product.image" />
              </div>
              <div class="text-left">
                <h4 class="ma-0 lg-text">
                  {{
                    default_product.product.name
                      ? default_product.product.name.length > 35
                        ? default_product.product.name.substring(0, 35).concat('...')
                        : default_product.product.name
                      : ''
                  }}
                </h4>
                <span
                  v-if="default_product.product.brand"
                  class="font-italic font-weight-medium md-text"
                >
                  {{ default_product.product.brand }}
                </span>
              </div>
            </div>
            <div>
              <h3 class="mt-4 mb-2 lg-text">Your product</h3>
            </div>
            <v-divider></v-divider>
            <editProductForm class="mt-4" :product="default_product" :default_product="true" />
            <div>
              <h3 class="mt-8 mb-2 lg-text">Competitors products</h3>
            </div>
            <v-divider></v-divider>
            <editProductForm
              class="mt-4"
              v-for="element in products"
              :key="element.id"
              :product="element"
              @delete-product="deleteProduct"
              @undo-deletion-product="undoDeletionProduct"
            />
            <h4 v-if="!products.length" class="text-center mt-0">
              There is no competitors products
            </h4>

            <div>
              <h3 class="mt-8 mb-2 lg-text">Products urls to add to this match</h3>
            </div>

            <v-divider></v-divider>
            <div class="mt-4">
              <div
                v-for="(product, index) in products_to_add"
                :key="index"
                class="d-flex align-center"
                :class="index > 0 ? ' mt-4' : ''"
              >
                <v-text-field
                  :label="'New product url ' + (index + 1)"
                  v-model="product.url"
                  solo
                  clearable
                  hide-details
                  :background-color="product.background"
                  :color="product.color"
                  append-icon="mdi-delete"
                  @click:append="removeProductInput(index)"
                  @input="valueChanged(product)"
                ></v-text-field>
              </div>
              <div style="margin-top: 15px">
                <v-btn class="ma-1" text @click="addProductInput()">
                  <v-icon left>mdi-plus</v-icon> Add more
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="ma-0"
            v-if="!loading"
            v-show="confirmation && !noChanges"
          >
            <v-row>
              <v-col cols="12" md="auto" sm="auto" class="d-flex align-center py-0">
                <div class="pl-2"><v-icon dark color="secondary">$circle</v-icon> Old url</div>
                <div class="pl-2"><v-icon dark color="orange">$circle</v-icon> Updated url</div>
                <div class="pl-2"><v-icon dark color="red">$circle</v-icon> Product to delete</div>
                <div class="pl-2"><v-icon dark color="green">$circle</v-icon> Product to add</div>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card v-if="to_update.length" class="w-100">
                  <v-card-text>
                    <h3 class="mt-0 mb-4 lg-text">Products has changes</h3>
                    <div
                      v-for="(product, index) in to_update"
                      :key="product.id"
                      class="product-name-text"
                    >
                      <div class="mb-4">
                        <span class="custom-label">Old url : </span>
                        <badge :text="product.old_url" color="secondary" />
                      </div>
                      <div class="mb-4">
                        <span class="custom-label">Updated url : </span>
                        <badge :text="product.url" color="orange" />
                      </div>
                      <hr v-if="index < to_update.length - 1" class="mt-4" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card v-if="to_delete.length" class="w-100">
                  <v-card-text>
                    <h3 class="mt-0 mb-4 lg-text">Products to delete</h3>
                    <div
                      v-for="(product, index) in to_delete"
                      :key="product.id"
                      class="mb-4 d-flex align-center"
                    >
                      <span class="custom-label d-flex align-center"
                        ><img
                          class="ml-auto favicon-img"
                          :src="
                            'https://8181.integ.crawlo.com/favicon?size=xs&website=' +
                            product.website_url
                          "
                          @error="replaceByDefault"
                          width="30"
                          height="30"
                      /></span>
                      <badge :text="product.url" color="red" />
                      <hr v-if="index < to_delete.length - 1" class="mt-4" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-card v-if="to_add.length" class="w-100">
                  <v-card-text>
                    <h3 class="mt-0 mb-4 lg-text">Products to add</h3>
                    <div
                      v-for="(product, index) in to_add"
                      :key="product + index"
                      class="mb-4 d-flex align-center"
                    >
                      <span class="custom-label d-flex align-center"
                        ><img
                          class="ml-auto favicon-img"
                          :src="
                            'https://8181.integ.crawlo.com/favicon?size=xs&website=' +
                            urlFromProduct(product)
                          "
                          @error="replaceByDefault"
                          width="30"
                          height="30"
                      /></span>
                      <badge :text="product" color="green" />
                      <hr v-if="index < to_add.length - 1" class="mt-4" />
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="ma-0"
            v-if="!loading"
            v-show="confirmation && noChanges"
          >
            <h3 class="mt-0 text-center">There is no changes to edit !</h3>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="!confirmation">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">cancel</v-btn>
        <submitButton :submitFunction="editProduct" text="Save changes" color="primary" />
      </v-card-actions>
      <v-card-actions v-else class="d-flex">
        <v-btn color="primary" class="mr-auto" text @click="confirmation = false"
          ><v-icon left>$back</v-icon>Back</v-btn
        >
        <v-btn color="primary" text @click="dialog = false">cancel</v-btn>
        <submitButton
          v-if="!noChanges"
          :submitFunction="confirmEditProduct"
          :submit_loading="submit_loading"
          text="Yes, edit it"
          color="secondary"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  import * as URL from 'url'
  const isUrl = require('is-url')
  export default {
    name: 'editMatchModal',
    componenets: {},
    props: ['ProductToEdit', 'show', 'component_name'],
    data() {
      return {
        dialog: false,
        loading: false,
        submit_loading: false,
        element: {
          id: '',
          ref: '',
          urls: '',
        },
        active_tab: 0,
        products: [],
        default_product: '',
        data_snapshot: {},
        urls_snapshot: [],
        products_to_add: [{ url: '', color: '#CC1E3F', background: '#FFE9ED' }],
        deleted_product: [],
        confirmation: false,
        to_update: [],
        to_add: [],
        to_delete: [],
      }
    },
    computed: {
      noChanges() {
        return this.to_add.length == 0 && this.to_delete.length == 0 && this.to_update.length == 0
      },
    },
    methods: {
      deleteProduct(product) {
        this.deleted_product.push(product)
      },
      undoDeletionProduct(product) {
        this.deleted_product = this.deleted_product.filter((elt) => {
          if (elt.id == product.id) return false
          else return true
        })
      },
      urlFromProduct(product) {
        let chaine = URL.parse(product, false, true)
        return `${chaine.protocol}//${chaine.host}`
      },
      async editProduct() {
        this.to_update = []
        this.to_add = []
        this.to_delete = []
        if (this.active_tab == 0) {
          this.data_snapshot.products.map((elt) => {
            if (
              this.default_product != undefined &&
              this.default_product.product != undefined &&
              this.default_product.product.id == elt.product.id &&
              this.default_product.product.url != elt.product.url
            ) {
              this.to_update.push({
                ref: this.ProductToEdit.match_ref,
                id: this.default_product.product.id,
                url: this.default_product.product.url,
                old_url: elt.product.url,
                website: this.default_product.website.id,
                url_website: this.default_product.website.url,
              })
            }
            this.products.map((product) => {
              if (product.product.id == elt.product.id && product.product.url != elt.product.url) {
                this.to_update.push({
                  ref: this.ProductToEdit.match_ref,
                  id: product.product.id,
                  url: product.product.url,
                  old_url: elt.product.url,
                  website: product.website.id,
                  url_website: product.website.url,
                })
              }
            })
          })

          this.to_delete = this.deleted_product.map((elt) => {
            this.to_update = this.to_update.filter((update) => update.id != elt.product.id)
            return {
              id: elt.product.id,
              ref: this.ProductToEdit.match_ref,
              url: elt.product.url,
              website: elt.website.id,
              website_url: elt.website.url,
            }
          })

          this.to_add = this.products_to_add.map((elt) => elt.url)
          if (!this.products_to_add[0].url.length) this.to_add = []
        }
        this.confirmation = true
      },
      validateUrl(url) {
        try {
          // Create a new URL object, which will throw an error if the URL is invalid
          let parsedUrl = URL.parse(url)

          console.log(parsedUrl)

          if (parsedUrl.host == null) return false

          if (parsedUrl.protocol == null) return false

          if (parsedUrl.hostname == null) return false

          //should have a domain suffix (e.g. .com, .org, .net, etc.)
          if (parsedUrl.hostname.split('.').length < 2) return false

          // URL constructor will handle spaces, but we should normalize and check if it's a valid URL
          return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
        } catch (error) {
          // If URL constructor throws an error, the URL is invalid
          return false
        }
      },
      valueChanged(product) {
        if (this.validateUrl(product.url)) {
          console.log('isUrl(product.url)', this.validateUrl(product.url))
          product.background = '#ECFBE6'
          product.color = '#0BA058'
        } else {
          console.log('isUrl(product.url)', this.validateUrl(product.url))
          product.background = '#FFE9ED'
          product.color = '#CC1E3F'
        }
      },
      confirmEditProduct() {
        this.submit_loading = true
        axios
          .post('/api/products/editMatch', {
            data: {
              match_id: this.ProductToEdit.match_id,
              match_ref: this.ProductToEdit.match_ref,
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              to_delete: this.to_delete,
              to_add: this.to_add,
              to_update: this.to_update,
            },
          })
          .then((res) => {
            this.dialog = false
            this.$emit('show-notification', {
              msg: `The change that you have made, has been well processed`,
              type: 'success',
            })
            this.$emit('confirm-edit')
          })
          .catch(function (err) {
            console.log('submitErrorHandle FAILURE!', err)
          })
          .finally(() => (this.submit_loading = false))
      },
      addProductInput() {
        this.products_to_add.push({
          url: '',
          color: '#CC1E3F',
          background: '#FFE9ED',
        })
      },
      removeProductInput(index) {
        if (this.products_to_add.length > 1) this.products_to_add.splice(index, 1)
        else this.initialise_add_forms()
      },
      initialise_add_forms() {
        this.products_to_add = [{ url: '', color: '#CC1E3F', background: '#FFE9ED' }]
      },
      async modalOpened() {
        this.initialise_add_forms()
        this.deleted_product = []
        this.to_update = []
        this.to_add = []
        this.to_delete = []
        this.confirmation = false
        this.submit_loading = false
        this.loading = true
        await axios
          .get('/api/products/getmatchinfo', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              match: this.ProductToEdit.match_id,
              component: this.component_name,
              ref: this.ProductToEdit.match_ref,
            },
          })
          .then(async (res) => {
            this.data_snapshot = JSON.parse(JSON.stringify(res.data.match))
            this.element.ref = res.data.match.user_ref
            this.element.id = res.data.match.id
            let urls = []
            this.urls_snapshot = []
            this.default_product = res.data.match.defaultProduct
            this.products = await res.data.match.products.filter((elt, index) => {
              if (
                res.data.match.defaultProduct != undefined &&
                elt.product.id != res.data.match.defaultProduct.id
              ) {
                this.urls_snapshot.push({ url: elt.product.url, pos: index })
                urls.push(elt.product.url)
                return true
              } else {
                this.urls_snapshot.unshift({ url: elt.product.url, pos: index })
                urls.unshift(elt.product.url)
                this.default_product = elt
                return false
              }
            })
            this.element.urls = urls.join('\n')
          })
        this.loading = false
      },
    },
    mounted() {
      this.active_tab = 0
    },
    watch: {
      show: {
        handler() {
          this.dialog = true
          this.modalOpened()
        },
      },
    },
  }
</script>
<style scoped>
  .product-image-edit {
    height: 100px;
  }
  .brand-text {
    font-size: 1rem;
    font-weight: 600;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
  .custom-label {
    display: inline-block;
    width: 120px;
    text-align: end;
    margin-right: 10px;
  }
  .costum-bagde {
    text-transform: none;
    cursor: auto;
  }
</style>
